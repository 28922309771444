import logo from './logo.svg';
import './App.css';
import Accounts from './Accounts';
import FurconCard from './FurconCard';

function App() {
  return (
    <div className="App">
      <div className="App-header">
        <div className='Info'>
          <h1 className='Title'>Furrycon.Social</h1>
          <p className='SubTitle'>By Furry Conventions For Furry Conventions</p>
        </div>

        <div className='AccountsBox'>
          <div className='AccountsBorder'>
            <Accounts />
          </div>
        </div>
        <p className='furconMessage'>If you are a furry convention and would like to join this mastodon server, visit our <a href="https://mastodon.furrycon.social/about">About Page</a> to see how to apply.</p>
        
      </div>

    </div>
  );
}

export default App;
