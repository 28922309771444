import { useEffect, useState } from 'react';
import './FurconCard.css';

function FurconCard(props) {

  return (
    <a href={props.url} className="FurconCard">
        <div className='FurconCardContent'>
            <div className="FurconCardAvatar">
                <img src={props.avatar}></img>
            </div>
            <span className='FurconCardDisplayName'>
                <span className='FurconCardDisplayNameProper underline-hover'>{props.display_name}</span>
                <span className='FurconCardDisplayNameAccount underline-hover'>@{props.username}</span>
            </span> 
        </div>
        <div className='FurconCardArrowContainer'><i className='arrow right'></i></div>

    </a>
  );
}

export default FurconCard;
