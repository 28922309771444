import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import './Accounts.css';
import FurconCard from './FurconCard';
import Response from './fakeResponse.json'

function Accounts() {
  // const accounts = Response.sort((a, b) => {
  //   let userNameA = a.display_name.toLowerCase();
  //   let userNameB = b.display_name.toLowerCase();

  //   if (userNameA < userNameB) return -1;
  //   if (userNameA > userNameB) return 1;
  //   return 0;
  // });
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    useEffect(() => {
        fetch('https://furrycon.social/accounts', {
          headers: {
            'content-type': 'application/json'
          }})
            .then(res => res.json())
            .then(data => {
              data = data.sort((a, b) => {
                let userNameA = a.display_name.toLowerCase();
                let userNameB = b.display_name.toLowerCase();
            
                if (userNameA < userNameB) return -1;
                if (userNameA > userNameB) return 1;
                return 0;
              });
              console.log(data);
              setAccounts(data);
              setLoading(false);
            })
            .catch(error => {
              setError(true);
            });
    }, []);

  return (
    <span>
    {error
    ? <p className='error'>There was a problem herding our social media teams.
    <br />
    Try going straight to <a href = "https://mastodon.furrycon.social">Mastodon</a> instead.</p>
    :
    loading
    ? <p className='loading'>Herding the social media teams<span className='loadingDot1'>.</span><span className='loadingDot2'>.</span><span className='loadingDot3'>.</span></p>
    : <ul className='accountList'>
        {accounts.map(account => (
          <li className='accountListItem'>
            { <FurconCard 
              username={account.username} 
              display_name={account.display_name}
              url={account.url}
              avatar={account.avatar}
            /> }
          </li>
        ))}
      </ul>
    }

    </span>

  );
}

export default Accounts;
